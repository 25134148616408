import AttachFloatingIp from '@/layouts/Stack/components/AttachFloatingIp.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
// import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin],
  data() {
    return {
      nameFloat: '',
      isBalancer: false,
      isClose: false,
    };
  },
  computed: {},
  methods: {
    attachFloatingIp: function ({ port: port, float: float }) {
      // console.log(port, float);
      if (float === 'balancer') this.isBalancer = true;
      const that = this;
      let res = '';
      let mode = '';
      let time;
      return new Promise(() => {
        const selfName = 'AttachFloatingIp';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AttachFloatingIp,
          closeOnBackdrop: false,
          props: {
            port: port,
            float: float,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              float = data.id;
              // console.log(selfName);
              this.nameFloat = data.name;
              // console.log(this.modal.footer);
              Vue.set(this.modal, 'footer', {
                confirm: {
                  props: { title: this.$t('sure.confirm') },
                  on: {
                    click: () => {
                      if (this.isClosed) {
                        // console.log(this.isClosed, 'wewewewewewe');
                        this.$modals.close({ name: selfName });
                      } else {
                        Vue.set(this.modal, 'footer', {
                          confirm: {
                            props: { loading: true },
                          },
                        });
                        this.removeOldFloat()
                          .then(() =>
                            this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips')
                          )
                          .then(() => {
                            this.attachFloatingIpToPort(port, float)
                              .then(data => {
                                if (data.status === 200) {
                                  this.$modals.close({ name: selfName });
                                  this.fetchServer();
                                  // console.log('wewewewe', this.isBalancer);
                                  this.showResModal(
                                    `Плавающий IP <b>${this.nameFloat}</b> успешно привязан к <b>${
                                      this.isBalancer ? this.tariff.name : this.networkName
                                    }</b>.`
                                  );
                                } else this.$modals.close({ name: selfName });
                              })
                              .catch(e => {
                                console.log(e);
                                this.$modals.close({ name: selfName });
                              });
                          })
                          .catch(e => {
                            console.log(e);
                            this.$modals.close({ name: selfName });
                          });
                      }
                    },
                  },
                },
                cancel: {
                  on: {
                    click: () => {
                      that.$modals.close();
                    },
                  },
                },
              });
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
              this.isClosed = false;
            },
            notready: () => {
              this.isClosed = true;
              Vue.set(this.modal.footer, 'cancel', false);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
              Vue.set(this.modal.footer, 'centered', true);
              Vue.set(this.modal.footer.confirm.props, 'title', this.$t('close'));
            },
            ready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  // console.log(selfName);
                  if (this.isClosed) {
                    console.log(this.isClosed, 'wewewewewewe');
                    this.$modals.close({ name: selfName });
                  } else {
                    Vue.set(this.modal, 'footer', {
                      confirm: {
                        props: { loading: true },
                      },
                    });
                    this.removeOldFloat()
                      .then(() =>
                        this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips')
                      )
                      .then(() => {
                        this.attachFloatingIpToPort(port, float)
                          .then(data => {
                            if (data.status === 200) {
                              this.$modals.close({ name: selfName });
                              this.fetchServer();
                              // console.log('wewewewe', this.isBalancer);
                              this.showResModal(
                                `Плавающий IP <b>${this.nameFloat}</b> успешно привязан к <b>${
                                  this.isBalancer ? this.tariff.name : this.networkName
                                }</b>.`
                              );
                            } else this.$modals.close({ name: selfName });
                          })
                          .catch(e => {
                            console.log(e);
                            this.$modals.close({ name: selfName });
                          });
                      })
                      .catch(e => {
                        console.log(e);
                        this.$modals.close({ name: selfName });
                      });
                  }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async dettachFloatingIpToPortB(id, method = 'put') {
      const params = {
        port: null,
        id: id,
        ip: null,
        method: method,
      };
      return method === 'put'
        ? this.$store.dispatch('moduleStack/attachFloatingIpToPort', params).catch(e => {
            this.showError(e);
          })
        : this.$store.dispatch('moduleStack/dettachFloatingIpToPort', params).catch(e => {
            this.showError(e);
          });
    },
    removeOldFloat() {
      return new Promise((resolve, reject) => {
        if (this.isBalancer)
          this.dettachFloatingIpToPortB(this.floatingIpId.id, 'put')
            .then(data => {
              this.isProcessing = false;
              // mode = data;
              resolve();
            })
            .catch(e => {
              reject(e);
            });
        else resolve(true);
      });
    },
    attachFloatingIpToPort(port, id, ip) {
      const params = {
        port: port,
        id: id,
        ip: ip,
      };
      return this.$store.dispatch('moduleStack/attachFloatingIpToPort', params).catch(e => {
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        // html: true,
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
  },
};
