<template>
  <div class="resize">
    <div>
      <div v-if="quotaFloatingIP === 0 && options.length === 0" class="l-col">
        <label class="typo__label standart-text">{{ $t('floatingIpError') }}</label>
      </div>
      <div v-else>
        <!--        <label v-if="options.length > 0 && isOk" class="resize-row standart-text">{{          /-->
        <!--          $t('attach')-->
        <!--        }}</label>-->
        <div v-if="options.length > 0 && isOk" class="l-col">
          <base-select
            v-model="currentFloat"
            :hide-selected="true"
            select-label=""
            :searchable="false"
            :options="options"
            :field-label="$t('attach')"
            size="medium"
            :allow-empty="false"
            :preselect-first="true"
            class="resize-row-item"
            @input="onChange"
          >
          </base-select>
        </div>

        <div v-else-if="!isOk" class="l-col">
          <!--          <label class="l-col standart-title">{{ $t('router') }}</label>-->
          <!--          <label class="standart-title">{{ $t('router') }}</label-->
          <label class="standart-text">{{ $t('router') }}</label
          ><br /><br />
          <!--          <label class="standart-title">{{ $t('gotoRouter') }}</label>-->
          <label class="standart-text">{{ $t('gotoRouter') }}</label>
          <!--          <plain-button color="primary" class="l-col" @click="routeTo()">-->
          <plain-button color="primary" class="" @click="routeTo()">
            {{ $t('toRouter') }}
          </plain-button>
        </div>
        <div v-else class="l-col">
          <!--          <label class="resize-row-text standart-title">{{ $t('empty') }}</label>-->
          <label class="standart-text">{{ $t('empty') }}</label>
          <!--          <div class="l-col">-->
          <label class="standart-text">{{ $t('text') }} </label>

          <plain-button color="primary" class="resize-row-text" @click="newIp()">
            {{ $t('create') }}
          </plain-button>
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import newIp from '@/layouts/Stack/mixins/newIp';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'AttachFloatingIp',
  components: { BaseSelect },
  mixins: [setFocus, newIp],
  props: {
    port: {
      type: String,
      default: '',
    },
    float: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentFloat: [],
      networkWithSubnet: [],
      isOk: false,
    };
  },
  computed: {
    quotaFloatingIP() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.floatingip
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.floatingip.limit -
          this.$store.state.moduleStack.quotasNetwork.floatingip.used
        );
      } else return 0;
    },
    options() {
      return (
        this.$store.state.moduleStack.floatingips
          // .filter(x => x.port_details === null || x.port_details.status === 'DOWN')
          .filter(port => !port.fixed_ip_address)
          .filter(x => x.floating_ip_address !== this.float)
          .map(x => `${x.floating_ip_address}`)
      );
    },
    currentPort() {
      return this.$store.state.moduleStack.ports.find(x => x.id === this.port);
    },
    networkId() {
      return this.$store.state.moduleStack.ports.find(x => x.id === this.port).network_id;
    },
    networks() {
      return this.$store.state.moduleStack.networks.find(x => x.id === this.networkId);
    },
    routers() {
      return this.$store.state.moduleStack.routers;
    },
  },
  watch: {
    currentPort: function (event) {
      // console.log(event);
    },
  },
  mounted() {
    this.isNotReady();
    this.portRouters();
    this.isSubnetWithRouter();
  },
  methods: {
    onChange(event) {
      this.$emit('ready');
      this.$emit('change', {
        id: this.$store.state.moduleStack.floatingips.find(x => x.floating_ip_address === event).id,
        name: this.$store.state.moduleStack.floatingips.find(x => x.floating_ip_address === event)
          .floating_ip_address,
      });
    },
    isNotReady() {
      return this.options.length === 0 ? this.$emit('notready') : this.$emit('ready');
    },
    portRouters() {
      this.$store.state.moduleStack.ports
        .filter(x => x.device_owner === 'network:router_interface')
        .forEach(x => {
          this.networkWithSubnet.push({ net: x.network_id, subnet: x.fixed_ips[0].subnet_id });
        });
      return this.networkWithSubnet;
    },
    routeTo() {
      this.$modals.close();
      this.$router.push({
        name: 'viewStackRouter',
      });
    },
    isSubnetWithRouter() {
      const current = {
        net: this.currentPort.network_id,
        subnet: this.currentPort.fixed_ips[0].subnet_id,
      };
      this.isOk =
        this.networkWithSubnet
          .filter(x => x.net === current.net)
          .filter(x => x.subnet === current.subnet).length === 1;
      if (!this.isOk) return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Выберите плавающий IP:",
    "create": "плавающий IP",
    "gotoRouter": "Перейти в раздел",
    "floatingIpError": "Вы достигли квоты по использованию плавающих адресов, обратитесь в поддержку для изменения квоты.",
    "toRouter": "роутеры",
    "empty": "Нет доступных плавающих IP.  ",
    "router": "Этот порт не подключен к роутеру с выходом во внешнюю сеть.",
    "sure": {
      "confirm": "Подключить",
      "text": "-----------------"
    },
    "text": "Создать новый",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;

    &-text {
    //margin-top: 2rem;
      text-align: center;
    }
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
